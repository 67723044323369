var Tegeldepot = Tegeldepot || {};

Tegeldepot.RemoveChildClass = class {
    constructor(options) {
        this.hookEvents = this.hookEvents.bind(this);

        this.options = jQuery.extend({}, {
            linkSelector: '.remove-class-child',
        }, options);

        this.hookEvents();
    }

    hookEvents() {
        jQuery('body').on('mouseleave', this.options.linkSelector, (event) => {
            jQuery(this.options.linkSelector).find('*').removeClass('active');
        });
    }
};

jQuery(function () {
    new Tegeldepot.RemoveChildClass();
});